<template>
  <v-main>
    <introNavigation />
    <v-container fluid fill-height>
      <v-row align-center justify-center>
        <v-flex xs12 sm12 md12>
          <v-col cols="12" md="6" class="mx-auto">
            <p class="display-1">{{ $t('confirmActiveKey.welcomeTo') }}</p>
            <p class="text-welcome">
              {{ $t('confirmActiveKey.onlineShop') }}
              <span class="font-weight-bold">CONASI365</span>
            </p>
            <div v-if="isLoading">
              <p class="red--text">Đang xác thực liên kết</p>
              <v-progress-circular
                indeterminate
                color="red"
              ></v-progress-circular>
            </div>
            <div v-else-if="isSuccess">
              <p style="color: green;">{{ message }}</p>
            </div>
            <div v-else>
              <p style="color: red;">{{ message }}</p>
            </div>
            <v-btn class="primary" @click="onReturn">Trở về</v-btn>
          </v-col>
        </v-flex>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import IntroNavigation from "Components/IntroNavigation";
import { mapActions } from "vuex";
import functionUtils from "Utils/functionUtils";
export default {
  components: {
    IntroNavigation,
  },
  data: () => ({
    isSuccess: false,
    isLoading: false,
    message: null,
  }),
  created() {
    let code = this.$route.params.code;
    let filter = {
      code: code,
    };
    this.isLoading = true;
    this.CONFIRM_ACTIVE_LINK(filter)
      .then(
        function() {
          this.isLoading = false;
          this.isSuccess = true;
          this.message = "Thành công !";
        }.bind(this)
      )
      .catch(
        function(error) {
          this.isLoading = false;
          this.isSuccess = false;
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText;
            this.message = this.$t(errorText);
          } else {
            this.message = "Có lỗi xảy ra !";
          }
        }.bind(this)
      );
  },
  methods: {
    /**
     * Return
     */
    onReturn: function() {
      this.$router.push({
        name: "ConfirmActiveKeyView",
      });
    },
    ...mapActions(["CONFIRM_ACTIVE_LINK"]),
  },
};
</script>

<style lang="scss" scoped>
.text-welcome {
  color: #FE6D6D !important;
  font-size: 24px;
  font-weight: 600;
}
</style>
